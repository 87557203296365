@import '../mixins';
.auth-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: auto;
  .ant-form-item-control-input-content {
    text-align: center;
  }
  .auth-form-content {
    width: 536px;
    height: auto;
    margin: 60px 0;
    padding: 31px 52px 25px 51px;
    border-radius: 5px;
    backdrop-filter: blur(33px);
    // border: solid 1px #ebf2f9;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    > form {
      width: 100%;
    }
    .form-welcom {
      font-size: 20px;
      font-weight: 600;
      line-height: 1.5;
      color: #000;
    }
    .login-text {
      font-size: 14px;
      font-weight: normal;
      line-height: 1.6;
      color: #000000;
      margin-top: 50px;
      margin-bottom: 40px;
      letter-spacing: 0.13px;
      text-align: center;
    }
    .user-text {
      font-size: 14px;
      color: #000000;
      margin-bottom: 30px;
      text-align: center;
      a {
        margin: 0 2px;
        text-transform: uppercase;
        text-decoration: underline;
        font-weight: 500;
      } 
    }
    button[type='submit'] {
      margin-top: 10vh;
      max-width: 229px;
      @include mq('tablet') {
        margin-top: 5vh;
      }
    }
    .logo-holder {
      margin-bottom: 40px;
      box-shadow: 0 20px 60px 0 #6bc04754;
      @include mq('tablet') {
        margin-bottom: 20px;
      }
    }
    @include mq('tablet') {
      width: 100%;
      height: auto;
      padding: 30px 40px;
      margin: 0;
    }
  }
  .forget-link {
    display: block;
    margin-bottom: 25px;
    line-height: 1.21;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: underline;
    margin-top: 20px;
    letter-spacing: -0.42px;
    &:lang(ar) {
      letter-spacing: 1px;
    }
  }
  &.auth-login-wrapper {
    display: flex;
    align-items: stretch;
    .auth-login-img {
      width: 65%;
      background-image: url(../../assets/img/Log-in-screen.png);
      // height: 100%;
      min-height: 100vh;
      background-repeat: no-repeat;
      background-size: cover;
    } 
    @include mq('ipad') {
      > div{
        width: 100% !important; 
      }
      .auth-login-img{
        display: none !important;
      }
    }
    .auth-form-content .form-welcom {
      margin-bottom: 50px;
      @include mq('tablet') {
        margin-bottom: 40px;
      }
    }
    .auth-form-content {
      width: 50%;
      margin: 0;
      .ant-form {
        width: 330px;
        button[type='submit'] {
          margin-top: 40px;
          margin-bottom: 30px;
          @include mq('tablet') {
            margin-top: 20px;
            margin-bottom: 20px;
          }
        }
      }
    }
    @include mq('tablet') {
      flex-direction: column-reverse;
      .auth-login-img {
        display: none;
      }
      > div {
        width: 100% !important;
      }
    }
  }
  .site-form-item-icon {
    color: #6cc04a;
  }
}

.facebook-login-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 26px;
  p {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.36px;
    color: #000;
    margin-right: 20px;
    &:lang(ar) {
      margin-right: 0;
      margin-left: 20px;
    }
  }
  img {
    margin-bottom: 5px;
    margin-left: 5px;
  }
}

.auth-wrapper.password-change-wrapper {
  .auth-form-content {
    .login-text {
      margin: 20px 0;
    }
    button[type='submit'] {
      margin-top: 0;
    }
  }
}

.googleIconStyle {
  box-shadow: none !important;

  &:active {
    background: transparent !important;
  }
}

.product-card-wrapper {
  padding-top: 40px;
  border-radius: 10px;
  background-color: #ffffff;
  position: relative;
  display: flex;
  flex-direction: column;
  transition: all ease-in-out 0.3s;
  max-width: 320px; 
  box-shadow: 0 20px 40px 0 rgba(116, 116, 116, 0.16);
  @media only screen and (max-width: 992px) {
    max-width: initial; 
}
&.alt-product{
  .product-img, .product-type, .product-name, .price-holder, .product-discount{
    opacity: 0.5;
    pointer-events: none;
  }
}

.out-stock-holder{
  position: absolute;
  top: 0;
  z-index: 55;
  left: 0;
  right: 0;
  width: 100%;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #666666;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.81;
  letter-spacing: 0.15px;
  color: #fff; 
  border-radius: 10px 10px 0 0;
  & + .product-discount{
    border-radius: 10px 10px 0 0;
  }
}
.card-control{
  flex: 1;
  display: flex;
  align-items: flex-end;
  > .ant-spin-nested-loading{
    width: 100%;
  }
  .ant-btn-lg.ant-btn-block{
    font-size: 14px;
  font-weight: 600;
  }
}
  .product-img {
    width: 208px;
    height: 124px;
    margin: auto;
    margin-top: 0;
    margin-bottom: 20px;
    cursor: pointer; 
    .ant-image{
      width: 100%;
      height: 100%;
    }
    img { 
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .product-name {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.6;
    color: #000000;
    text-align: center;
    margin-bottom: 5px;
    min-height: 50px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    padding: 0 10px;
  }
  .price-holder {
    text-align: center;
    margin-bottom: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    justify-content: space-evenly; 
    p {
      font-size: 16px;
      font-weight: 600;
      color: #6cc04a;
    }
    .price-before {
      font-size: 14px;
      color: #f00;
      text-decoration: line-through; 
      margin-left: 12px;
      &:lang(ar){
        // margin-left: 0;
        margin-right: 12px;
      }
    }
  }
  .product-discount {
    position: absolute;
    top: 0;
    left: 0;
    width: 68px;
    height: 68px;
    padding: 24px 14px 27px 15px;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 0;
    z-index: 2;
    box-shadow: 0 20px 40px 0 rgba(116, 116, 116, 0.16);
    background-color: #da1b15;
    &:lang(ar){
      border-radius: 0;      
      border-bottom-left-radius: 10px; 
      border-top-right-radius: 0;
      right: 0;
      left: auto;
    }
  }
  .card-count-holder{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .product-count{
      width: 36px;
      text-align: center;
      font-size: 22px;
      user-select: none; 
    }
    svg{ 
      font-size: 22px;
      fill: #fff;
      cursor: pointer;
    }
    button{
      border: 0; 
      padding: 0;
      border-radius: 10px;
      width: 33px;
      height: 33px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #4D9D2C;
      @media only screen and (max-width: 992px) {
        width: 30px;
        height: 30px;
        svg{
          font-size: 20px;
        }
      }
      .ant-btn-loading-icon{
        position: absolute;
        right: 0;  
        width: 30px;
        background: #FFF;
      }
      &:disabled{
        // opacity: 0.5; 
        svg{
        }
      }
    }
    .dlt-btn{
      // position: absolute;
      // right: -10px;
      // transform :scale(0);
      // transition: all ease 0.2s;
      // &:lang(ar){
      //   right: auto;
      //   left: -10px;
      // } 
      svg{
        fill: #ff0000;
        font-size: 28px;
      }
    }
  }

  &:hover{
    transition: all ease-in-out 0.3s;
    // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    .dlt-btn{
      transform :scale(1); 
      transition: all ease 0.3s;
    }
  }
}

.product-type{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 18px;
  .select-product-type{
    margin-left: 10px;
  }
  .ant-select.select-product{
    .ant-select-selector{
      height: 37px;
      display: flex; 
      align-items: center;
      border-radius: 20px;
      background-color: #f2ffec; 
      box-shadow : none !important;
      .ant-select-selection-item{
        padding: 0 !important; 
        font-size: 16px; 
        font-weight: 500;
        letter-spacing: -0.48px;
        color: #6bc047;
      }
      .ant-select-arrow{
        color: #000; 
      }
    }
    .ant-select-arrow{
      color: #000;
    }
  }
}

.card-count-holder{
  display: flex;
  flex-direction: column;
  padding: 8px 17px 11px 22px;
  border-radius: 10px;
  background-color: #6cc04a;
  .card-count-info{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color:#FFF;
    margin-bottom: 6px;
    letter-spacing: -0.42px;
    font-size: 14px; 
    font-weight: normal;
    line-height: 2.14;
    &:lang(ar) {
      letter-spacing: 1px;
    }
    .item-count{
      &:lang(ar) {
        direction: rtl;
      }
    }
  }
}
.card-count-btns{
  display: flex;
  align-items: center;  
  justify-content: space-between;
  width: 100%;
  .btns-wrapper{
    display: flex;
    align-items: center;
    .product-count{
      color: #FFF;
      font-size: 16px;
      font-weight: 600;
    }
  }
}

.cart-product-card {
  display: flex;
  border-radius: 10px;
  border: solid 1px #dbdbdb;
  margin-bottom: 20px;
  padding-bottom: 20px;
  padding: 28px 38px 18px 20px;
  .product-img {
    width: 100px;
    height: 80px;
    margin-right: 48px;
    &:lang(ar){
      margin-right: 0; 
      margin-left: 48px;
    }
    .ant-image{
      width: 100%;
      height: 100%;
    }
    img { 
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .cart-product--content{
    flex : 1;
    .product-info{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
    }
    .product-control-holder{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .product-name {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.6; 
    color: #000000; 
    &:lang(ar) {
      letter-spacing: 1px;
    }
  }
  .price-holder {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-evenly; 
    p {
      font-size: 16px;
      font-weight: 600;
      color: #6cc04a;
    }
    .price-before {
      font-size: 14px;
      font-weight: 600;
      color: #f00;
      text-decoration: line-through;
      margin-left: 16px;
      &:lang(ar){ 
        margin-left: 0;
        margin-right: 16px;
      }
    }
  }
  .product-discount {
    position: absolute;
    top: 0;
    left: 0;
    width: 68px;
    height: 68px;
    padding: 24px 14px 27px 15px;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 0; 
    z-index: 2;
    box-shadow: 0 20px 40px 0 rgba(116, 116, 116, 0.16);
    background-color: #da1b15;
    &:lang(ar){
      border-radius: 0;
      border-bottom-left-radius: 10px; 
      border-top-right-radius: 0;
      right: 0;
    }
  }
  .card-count-holder{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: row;
    background-color: #fff;
    padding: 0;
    .product-count{
      width: 50px; 
      text-align: center;
      font-size: 24px;
      font-weight: 500;
      user-select: none; 
    }
    svg{
      font-size: 22px;
      fill: #fff;
      cursor: pointer;
    }
    button{
      border: 0; 
      padding: 0;
      height: auto;
      border-radius: 10px;
      width: 33px;
      height: 33px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #4D9D2C;
      .ant-btn-loading-icon{
        position: absolute;
        right: 0;  
        width: 30px;
        background: #FFF;
      }
      &:disabled{
        // opacity: 0.5;
        svg{
        }
      }
    }
    // .dlt-btn{
    //   position: absolute;
    //   right: 0;
    //   transform :scale(0);
    //   transition: all ease 0.2s;
    //   &:lang(ar){
    //     right: auto;
    //     left: 0;
    //   }
    //   svg{
    //     fill: #ff0000;
    //     font-size: 28px;
    //   }
    // }
  }

  .product-fav-dlt{
    .dlt-btn{
      margin-right: 40px;
      &:lang(ar){
          margin-right: 0;
          margin-left: 40px;
        }
        svg{
          fill: #ff0000; 
        }
    }
       svg{
        // fill: #ff0000;
        font-size: 26px;
        cursor: pointer; 
      }
  }
}

.fav-card-share{
  display: flex;
  align-items: center;
  .share-btn{
    margin-left: 30px; 
    cursor: pointer;
    &:lang(ar){
      margin-left: 0; 
      margin-right: 30px;
    }
  }
}

.product-fav-icon-holder{
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 4;
  &:lang(ar){ 
    right: auto;
    left: 20px;
  }
  svg{
    font-size: 24px;
  }
}

@media only screen and (max-width: 992px) {
  .fav-card-share .share-btn{
    margin: 0 !important;
  }
  .cart-product-card .product-fav-dlt .dlt-btn{
    margin-right: 20px;
    margin-left: 10px;
    &:lang(ar){
      margin-left: 20px;
      margin-right: 10px; 
    }
  }
  .cart-product-card .product-img{
    min-width: 90px;
    margin-right: 10px;
    &:lang(ar){
      margin-left: 10px;
      margin-right: 0; 
    }
  }
  .cart-product-card .cart-product--content .product-info{
    flex-direction: column;
    .product-name{
      margin-bottom: 20px;
    }
  }
  .cart-product-card{
    padding: 20px 16px;
  }
}

.faq-page-wrapper {
    background-color: #fff;
    .faq-hero {
      height: 380px;
      // background-image: url('../../assets/img/Recipes_bk.png');
      background-image: url('https://metro-markets.com/plugins/user/images/faqeng.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      &:lang(ar) {
        // background-image: url('../../assets/img/Recipie-ar.jpeg');
      }
      h1 {
        color: #ffffff;
        text-transform: uppercase;
        font-size: 44px;
        font-weight: 600;
        line-height: 1.55;
      }
      @media only screen and (max-width: 992px) {
        height: 180px;
        h1 {
          font-size: 34px;
        }
      }
    }
    .ant-breadcrumb {
      margin-top: 45px;
      margin-bottom: 30px;
    }
    .faq-content {
      width: 85%;
      margin: auto;

      @media only screen and (max-width: 992px) {
        width: 100%;
        
      }
    }
  }

  .faq-content-holder{
      margin-bottom: 10vh;
      h2{
        font-size: 25px;
        color: #000;
        letter-spacing: -0.75px;
        font-weight: 500;
        margin-bottom: 20px;
        margin-top: 35px;
      }
      .ant-collapse > .ant-collapse-item > .ant-collapse-header{
        background-color: #6cc04a;
        color: #FFF;
        font-size: 18px;
      }
      .ant-collapse-borderless > .ant-collapse-item{
          border: 0;
          margin-bottom: 16px;
      }
      .ant-collapse-content > .ant-collapse-content-box{
        font-size: 16px;
        color: #000;
        padding-top: 16px !important; 
        p{
          margin-bottom: 10px;
        }
        strong{
          font-weight: 600;
        }
        h5{
          font-size: 18px;
          font-weight: 500;
          margin-bottom: 10px;
        }
        ul{
          margin-bottom: 20px;
          li{
            margin-bottom: 6px;
          }
        }
        h6{
          font-size: 16px;
          margin-bottom: 10px;
        }
      }
  }
.root {
  position: relative;
}
.link{
    font-size: 16px;
    font-weight: normal;
    line-height: 1.56;
    letter-spacing: 0.15px;
    color: #000000 !important;
}
.loading {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 99999999999999999999999999;
  // background: url(../../../images/299.gif) center no-repeat #FFF;
}

.breadcrumb { 
  background: transparent;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  padding-top: 0;
  > li {
    display: flex;
    align-items: center;
    text-transform: capitalize;
    a {
      font-size: 14px;
      font-weight: bold;
      line-height: 1.5;
      letter-spacing: 1.4px;
      color: #242729;
      text-transform: uppercase;
      &:lang(ar) {
        letter-spacing: normal;
      }
    }
    &.active {
      color: #242729;
      font-weight: normal;
      text-transform: capitalize;
    }
    &:not(:first-of-type) {
      &::before {
        content: "\203A";
        font-size: 29px;
        margin-bottom: 5px; 
        font-weight: 400;
        color: #0e1317;
      }
    }
  }
}

.slider-heading {
  margin-bottom: 30px;
  @include mq("tablet") {
    margin-bottom: 30px;
  }
  h3 {
    display: flex;
    align-items: center;
    font-size: 25px;
    font-weight: 500;
    letter-spacing: -0.75px;
    color: $primary;
    line-height: 1;
  }
  @include mq("tablet") {
    margin-bottom: 30px;
  }
}

.slick-slider {
  .slick-dots {
    bottom: -50px;
    li {
      button {
        background-color: #fff;
        border: 2px solid $primary;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        transition: background-color ease 0.3s;
        &::before{
          content: "";
        }
      }
      &:hover,
      &.slick-active {
        button {
          background-color: $primary;
        }
      }
    }
  }
  @include mq("phablet") {
    .product-card,
    .company-card,
    .brand-card {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.call-tel-style{
    overflow: hidden;
    position: relative;
    .tel,
    .text{
        white-space: nowrap;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all ease-in-out 0.3s;
    }
    .tel{
        transform: translate(-50%,40px);
        @include mq("phablet") { 
          font-size: 13px;
        } 
    }
    &:hover{
        .text{
            transform: translate(-50%,-40px);
        }
        .tel{
            transform: translate(-50%, -50%) scale(1.2);
        }
    }
}

.counter-action {
  margin-bottom: 12px;
  .qty {
    display: flex;
    height: 38px;
    border: solid 1px #e0e0e0;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    color: #242729;
    padding: 0;
    input {
      width: 40px;
      background: transparent;
      text-align: center;
      border: 0;
      outline: 0;
      font-weight: normal;
      font-size: 16px;
      // margin-top: -4px;
      pointer-events: none;
      color: #242729;
    }
    span {
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-weight: 500;
      height: 100%;
      user-select: none;
    }
  }
}

.empty-alert-wrapper{
  width: 100%;
  height: 100%;
  min-height: 30vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  display: none;
  h6{
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 500;
    color: #707070;
  }
  .add-new-address-btn{
    width: 181px;
  height: 48px;
  border: solid 1px #000000;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  }
}

.show-empty-alert{
  .empty-alert-wrapper{
    display: flex;
    & ~ header{
      display: none !important;
    }
    & ~ div{
      display: none !important;
    }
  }
}

.qty{
  .minus,
  .plus{
    cursor: pointer;
    &[disabled]{
      pointer-events: none;
      opacity: 0.3;
    }
  }
}

.price--after__0{
  display: none !important; 
}

// start Toastbar style

.toastbar{
  visibility: hidden; 
  min-width: 300px; 
  max-width: 45%;
  background-color: $primary;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  bottom: 30px;
  font-size: 14px;
  font-weight: 400;
  display: flex !important;
  align-items: center;
  flex-wrap: wrap;
  line-height: 1.2;
  z-index: 88888;
  > strong{
    font-weight: 500;
    font-size: 16px;
    padding: 0 4px;
    line-height: 1.2;
    &.name{
      max-width: 60%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      @include mq("tablet") { 
        max-width: 100%;
      }  
    }
  }
  &.show{
    visibility: visible;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }
}
/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}
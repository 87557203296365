html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: middle;
  // font-family: proxima,cairo;
  // font-family: 'Montserrat', sans-serif;
}

button:focus {
  outline: 0 !important;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body,
html {
  line-height: 1;
  font-size: 16px;
  overflow-x: hidden;
  overflow-y: auto !important;
  width: 100% !important;
  height: 100%;
  // font-family: proxima,cairo;
  // zoom: 0.95;
  font-family: 'Poppins', sans-serif; 
  @include mq("phablet") { 
    font-size: 15px;
    zoom: 1;
  } 
  &:lang(ar){
    direction: rtl;
    font-family: 'Cairo', sans-serif;
  }
}
body{
    /* width */
    &::-webkit-scrollbar {
      width: 7px;
      // display: none;
    }
  
    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      // display: none;
    }
  
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #6cc04a;
      // width: 6px;
      border-radius: 4px;
      // display: none;
    }
    // scrollbar-width: none;
}
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden] {
  display: none;
}

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
.tooltip {
  z-index: 100;
}

a:focus {
  outline: none;
  text-decoration: none !important;
}
input:focus {
  outline: none !important;
  box-shadow: none !important;
}
a:active,
a:hover {
  outline: 0;
  text-decoration: none;
}
select:focus{
  outline: none;
}

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
}

figure {
  margin: 0;
}

form {
  margin: 0;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
  white-space: normal;
  *margin-left: -7px;
}

button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
}

button,
input {
  line-height: normal;
}

button,
select {
  text-transform: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.k-animation-container,
.k-animation-container *,
.k-animation-container :after,
.k-block .k-header,
.k-dialog .k-window-content,
.k-list-container,
.k-widget,
.k-widget *,
.k-widget :before {
  box-sizing: border-box;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px #e9ffde inset !important;
}

// size of icon
[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-size: 20px !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

@include mq("ipad") { 
  .collapse:not(.show) {
    display: none !important;
  }
}

@include mq("tablet", 'min') { 
  .container, .container-md, .container-sm {
      max-width: 100%;
  }
}

.container, .container-sm {
  max-width: 100%;
  width: 100%;
  margin: auto;
  padding: 0 16px;
}


@media (min-width: 1200px){
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1280px;
  }

}
@media (min-width: 1360px){
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1305px;
  }
}

@media (min-width: 1440px){
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1305px;
  }
}
@media (min-width: 1900px){
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1700px;
  }
}

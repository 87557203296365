.faq-page-wrapper {
  background-color: #fff;
  .ant-breadcrumb {
    margin-top: 45px;
    margin-bottom: 30px;
  }
  .terms-content {
    width: 80%;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 40px;
    @media only screen and (max-width: 992px) {
      width: 100%;
    }
  }
}

.faq-content-holder {
  margin-bottom: 10vh;
  h2 {
    font-size: 25px;
    color: #000;
    letter-spacing: -0.75px;
    font-weight: 500;
    margin-bottom: 20px;
    margin-top: 35px;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    background-color: #6cc04a;
    color: #fff;
    font-size: 18px;
  }
  .ant-collapse-borderless > .ant-collapse-item {
    border: 0;
    margin-bottom: 16px;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    font-size: 16px;
    color: #000;
    padding-top: 16px !important;
    p {
      margin-bottom: 10px;
    }
    strong {
      font-weight: 600;
    }
    h5 {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 10px;
    }
    ul {
      margin-bottom: 20px;
      li {
        margin-bottom: 6px;
      }
    }
    h6 {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
}

.terms-article-holder {
  border-radius: 10px;
  border: solid 1px #ececec;
  background-color: #ffffff;
  padding: 30px 40px 50px 30px;
  max-height: 70vh;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 8px;
    // display: none;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    // display: none;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #6cc04a; 
    // width: 6px;
    border-radius: 4px;
  }
}
.terms-article-holder .top-heading {
  font-size: 30px;
  font-weight: bold;
  line-height: 1.35;
  letter-spacing: -0.6px;
  color: #000000;
  text-align: center;
}
.title-of-article h1{
  font-size: 40px;
  font-weight: bold;
  line-height: 1.15;
  text-align: center;
  color: #6cc04a;
  margin-bottom: 20px;
}
.terms-article-holder p {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.6;
  color: #000000;
  margin: 20px 0;
}
.terms-article-holder h2 {
  font-size: 22px;
  font-weight: bold;
  line-height: 1.35;
  color: #000000;
}

.terms-article-holder ul li {
  font-size: 17px;
  font-weight: normal;
  line-height: 1.6;
  color: #000000;
  margin: 20px 0;
  margin-left: 70px;
}
.terms-article-holder h4{
  font-size: 21px;
  font-weight: bold;
  line-height: 1.35;
  color: #6cc04a;
  margin: 15px 0;
}

.terms-article-holder h3{
  font-size: 19px;
    font-weight: bold;
    line-height: 1.35;
    color: #000000;
}

.terms-article-holder p.start-parg{
  margin-left: 70px;
  font-size: 17px;
}

.terms-article-holder a{
  color: #6cc04a;
  font-weight: 500;
}

.order-page-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #fff;
  }
  .order-main {
    padding: 40px 28.5px 26px;
    border-radius: 5px;
    // box-shadow: 0 10px 20px 0 #ebf2f9;
    background-color: #ffffff;
    max-width: 800px;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .order-thank{
      font-size: 14px;
      font-weight: 600;
  color: #6cc04a;
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 30px;
    }
    .order-p-text{
      font-size: 16px;
      font-weight: 600;
      color: #000;
    }
    .order-placed-p{
        font-size: 20px;
  font-weight: 500;
  color: #000000;
  letter-spacing: 0.19px;
  text-align: center; 
  line-height: 1.6;
    }
    .order-placed-btns{
        margin-top: 30px;
        button{
          width: 150px; 
          &:first-of-type{
            background-color: #6bc047;
            border: 0;
          }
        }
    }
  }
.category-page-wrapper{
    background-color: #f9fff6; 
}

.category-hero{
    background-image: url(../../assets/img/category_bk.png);   
    height: 380px;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    h2{
        color: #ffffff;
      text-transform: uppercase;
      font-size: 44px;
      font-weight: 600;
      line-height: 1.55;
    }
    @media only screen and (max-width: 992px) {
        height: 180px;
        h2{
            font-size: 34px;
        }
    }
}

.category-page-content{
    padding-top: 65px;
    // padding-bottom: 65px;
    padding-bottom: 20px;
    @media only screen and (max-width: 992px) {
        padding-top: 20px;
    }
}

.category-view-wrapper{
    display: flex;
    margin-top: 250px; 
    @media only screen and (max-width: 992px) {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .category-aside-holder{ 
        width: 270px;
        min-width: 270px;
        height: fit-content;
        padding: 4px 24px 28px 22px; 
        border-radius: 10px;
        box-shadow: 0 20px 40px 0 rgba(116, 116, 116, 0.16);
        background-color: #ffffff;
        position: sticky; 
        top: 10px;
        margin-right: 30px;
    @media only screen and (max-width: 992px) {
        padding-top: 30px;
    }
        &:lang(ar){
            margin-right: 0;
            margin-left: 30px;
        }
        .ant-collapse > .ant-collapse-item > .ant-collapse-header{
            font-size: 16px;
            font-weight: normal;
            line-height: 1.19;
            letter-spacing: 1.6px;
            color: #242729;
            padding-left: 0; 
            padding-right: 0;
            &:lang(ar) {
                letter-spacing: normal;
              }
        }
        .category-filter-holder .filter-title{
            font-size: 14px;
            font-weight: bold;
            line-height: 1.19;
            letter-spacing: 1.6px;
            color: #6cc04a;
            padding-left: 0;
            padding-right: 0;
            text-transform: uppercase;
            &:lang(ar) {
                letter-spacing: normal;
              }
        }
        .category-filter-holder .ant-collapse-horizontal{
            color: #e9ffde;
            border: solid 1px #e9ffde;
            margin: 4px 0;
        }
        .category-filter-holder .filter-title{
            margin-top: 30px;
            padding-bottom: 12px;
        }
        .ant-collapse-content-box{
            padding: 0 !important;
        }
        .ant-checkbox-group{
            display: flex;
            flex-direction: column;
            max-height: 300px;
            overflow: auto;
            > label{
                margin-bottom: 10px;
                font-size: 14px; 
                font-weight: normal;
                display: flex;
                align-items: center;
                .ant-checkbox-inner{
                    border-radius: 4px;
                    border: solid 2px #6cc04a;
                    width: 20px; 
                    height: 20px;
                }
            }
            .ant-checkbox + span{
                padding-top: 4px;
                font-size: 14px; 
                font-weight: normal;
                user-select: none;
            }
            &::-webkit-scrollbar {
                width: 8px;
                // display: none;
              }
            
              /* Track */
              &::-webkit-scrollbar-track {
                background: #f1f1f1;
                // display: none;
              }
            
              /* Handle */
              &::-webkit-scrollbar-thumb {
                background: #6cc04a;
                // width: 6px;
                border-radius: 4px;
                // display: none;
              }
        }
        .slider-price-rang{
            display: flex;
            align-items: center;
            justify-content: space-between; 
            p{
                font-size: 14px;
                font-weight: normal;
                letter-spacing: 1.4px;
                color: #242729; 
                &:lang(ar) {
                    letter-spacing: normal;
                  }
            }
        }
        @media only screen and (max-width: 992px) {
            // display: none; 
            position : fixed;
            top: 0;
            left: 0;
            bottom : 0;
            height: 100vh;
            overflow: auto;
            margin: 0; 
            z-index: 999;  
            width: 90%;
            transform: translateX(-100%);
            transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            margin-left: 0 !important;
        }
        &.active{
            transform: translate(0) !important;
        }
    }

    .category-list-holder{
        flex: 1;
        position: relative;
        width: calc(100% - 300px);
        .category-levels-wrapper{
            width: 100%;
            height: 66px;
            border-radius: 10px;
            border: solid 1px #6bc047;
            background-color: #e9ffde;
            display: flex;
            align-items: center;
            padding: 0 25px;
            overflow: auto;
            .ant-tabs-nav{
                margin-bottom: 0;
            }
            .ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before{
                // display: none;
            }
            .ant-tabs-tab + .ant-tabs-tab{
                height: 63px;
            }
            .ant-tabs-nav-operations{
                .ant-tabs-nav-more{
                    padding: 0;
                    padding-left: 5px;
                    &:lang(ar) {
                        padding-left: 0;
                        padding-right: 5px;
                    }
                }
                .anticon{
                    display: block; 
                    font-size: 24px;
                }
            }
        }
        @media only screen and (max-width: 992px) {
            width: 100%;
            top: 0;
        }
    }
    .category-sections-holder{
        display: flex;
        // margin-bottom: 60px;
        height: 160px;
        @media only screen and (max-width: 992px) {
            height: auto;
            overflow: auto;
        }
        > .section-holder{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 25px;
            cursor: pointer;
            height: fit-content; 
            img{
                max-width: 54px;
                max-height: 54px;
                min-width: 54px;
                min-height: 54px; 
                mix-blend-mode: multiply;
                object-fit: contain;
            }
            &:lang(ar){
                margin-right: 0;
                margin-left: 25px;
            }
            &:last-of-type{ 
                margin: 0 !important;
            }
            .section-title{
                font-size: 12px;
                font-weight: normal; 
                line-height: 1.58; 
                color: #000000;
                margin-top: 6px;
                max-width: 120px;
                text-align: center;
            }
        }
    }
    &.is-brand{
        margin-top: 10px;
        .category-list-holder{
            // top: -50px;
        }
        .category-sections-holder{
            display: none; 
        }
    }
}

.category-product-wrapper{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 225px));
    gap : 50px 24px;   
    min-height: 200px; 
    @media only screen and (max-width: 992px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
    @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
    }
    @media only screen and (max-width: 500px) {
        grid-template-columns: 1fr 1fr;
        gap: 30px 16px;
        .product-card-wrapper{
            padding-top: 30px;
        }
        .product-card-wrapper .product-img{
            width: auto;
            height: 95px;
            margin-bottom: 0;
        }
        .product-card-wrapper .product-name{
            font-size: 14px;
        }
        .product-card-wrapper .price-holder p{
            font-size: 14px;
        }
        .product-card-wrapper .card-count-holder{
            padding: 8px;
            padding-top: 0;
        }
        .product-card-wrapper .product-discount{
            width: 50px;
            height: 50px;
            font-size: 13px;
        }
    }
    @media only screen and (max-width: 370px) {
        grid-template-columns: 1fr;
        gap: 30px 20px;
        .product-card-wrapper .product-img{
            width: auto;
        }
    }
    > .ant-skeleton{
        display: table;
        width: 100%;
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 40px;
        border-radius: 10px;
        background-color: #fff;
        position: relative;
        display: flex;
        flex-direction: column;
        transition: all .3s ease-in-out;
        max-width: 320px;
        box-shadow: 0 20px 40px 0 #74747429; 
        padding: 25px;
        padding-bottom: 50px;
        .ant-skeleton-header{
            margin-bottom: 24px;
        }
        @media only screen and (max-width: 992px) {
            max-width: initial;
        }
    }
    .no-products-wrapper{
        position: absolute;
        left: calc(50% - 150px);
        @media only screen and (max-width: 992px) {
            position: relative;
        }
    }
}

.filter-section{
    .ant-radio-wrapper{
        display: block;
        margin-bottom: 5px;
        font-size: 14px; 
        font-weight: normal;
        letter-spacing: 0.13px;
        color: #000;
    }
}
.close-filter-slide{
    display: none !important;
    font-size: 26px;
    position: absolute;
    top: 10px; 
    right: 10px;
    color: #fe5252 !important;
    z-index: 11;
    @media only screen and (max-width: 992px) {
        display: block !important; 
    }
}
.cat-header-title-filter{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 45px;
    .anticon {
        font-size: 35px;
        color: #6cc04a;
        cursor: pointer;
        display: none;
        @media only screen and (max-width: 992px) {
            display: block; 
            margin-left: 16px;
            &:lang(ar){
                margin-left: 0;
                margin-right: 16px;
            }
        }
    }
    @media only screen and (max-width: 992px) {
        margin-top: 20px;
    }
}

.ant-collapse-ghost > .ant-collapse-item.sort-by-title{
    .ant-collapse-header{
        font-size: 14px !important;
        font-weight: bold !important;
        line-height: 1.19 !important;
        letter-spacing: 1.6px !important;
        color: #6cc04a !important;
        text-transform: uppercase;
        &:lang(ar) {
            letter-spacing: 1px !important;
        }
    }
}
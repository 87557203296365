.address-action-btn{
  display: flex;
  align-items: center;
  .ant-divider{
    margin: 0 30px;
    height: 22px;
  }
    .dlt-btn{
      // margin-right: 40px;
      // &:lang(ar){
      //     margin-right: 0;
      //     margin-left: 40px;
      //   }
        svg{
          fill: #ff0000; 
        }
    }
       svg{
        fill: #6cc04a; 
        font-size: 25px; 
        cursor: pointer; 
      }
  }
.address-list-wrapper{
    .ant-radio-wrapper{
        width: 100%;  
        margin-bottom: 20px;
        span.ant-radio + *{
            width: 100%;  
        }
    }
    .address--name{
        font-size: 14px;
        letter-spacing: 0.13px;
        color: #000000;
    }
}
  .address-radio-holder{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .add-address-title{
    font-size: 16px;
    font-weight: normal;
    color: #ababab;
    text-transform: uppercase;
    margin-bottom: 16px;
  }

  .add-address-wrapper{
      padding-top: 20px; 
      textarea.ant-input{
        height: 127px !important;
        min-height: 127px !important;
        max-height: 127px !important;
      }
}

.address-list-wrapper{
      border-bottom: 1px solid #eeeeee;
    //   padding-bottom: 20px; 
    .ant-radio-group{
        width: 100%; 
    }
  }
  .address-header-text{
    font-size: 14px;
  font-weight: 500;
  line-height: 2.29;
  letter-spacing: -0.42px;
  color: #000;
  margin-bottom: 16px;
  &:lang(ar) {
    letter-spacing: 1px;
  }
  }

  .add-address-btn-holder{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content:center; 
    min-height: 20vh;
  }
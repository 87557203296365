.user-order-wrapper {
  .user-order--header {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between; 
    p {
      font-size: 15px;
      font-weight: 500;
      letter-spacing: -0.42px;
      color: #000;
      &:lang(ar) {
        letter-spacing: 1px;
      }
    }
    .ant-select{
      margin-left: auto; 
      &:lang(ar){
        margin-left: 0;  
        margin-right: auto; 
      }
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector{
      border: solid 1px #ebf2f9 !important;
    background-color: #fff;
    }
    .ant-select-single .ant-select-selector .ant-select-selection-item{
      color: #6cc04a; 
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
      border-color: #ebf2f9 !important; 
      box-shadow: none !important; 
    }
  }
  .process-order-card {
    padding: 15px 13px 16px 18px;
    border-radius: 10px;
    border: 1.5px solid#f3ba35;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    .process-order-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .process-order-number {
          p{
            display: inline;
            font-weight: 500;
          }
        .order-title {
          font-size: 16px;
          color: #707070;
        }
        .order-num {
          font-size: 16px;
          color: #000000;
          margin: 0 4px;
        }
        .order-status {
          font-size: 14px;
          color: #ababab;
        }
      }
      time {
        font-size: 14px;
        color: #000;
      }
    }
    .process-order-action{
        display: flex;
        align-items: center;
        margin-top: 16px; 
        // p{text-transform: uppercase;} 
       .track-order{
        font-size: 14px;
        font-weight: 600;
        color: #6cc04a;
        margin-right: 30px;
        cursor: pointer;
        &:lang(ar){
            margin-right: 0;
            margin-left: 30px;
        }
    } 
    .cancel-order{
        font-size: 14px;
        font-weight: 600;
        border-radius: 10px;
        background-color: #fff6f6;
        border-radius: 10px;
        cursor: pointer;
        padding: 12px;
        color: #f82727;
        letter-spacing: -0.42px;
        &:lang(ar) {
          letter-spacing: 1px;
        }
        &.alternative-order-btn{
          background-color: #e9ffde;
          color: #6cc04a;
        }
    }
    }
  }
}

.user-order-type-prev{
  margin-top: 30px; 
  .process-order-card{
    background-color: #e9ffde;
    border : 0;
    cursor: pointer; 
    .order-title{
      color: #000 !important;
    }
    .order-num{
      color: #6cc04a !important;
    }
  }
}

.old-order-tilte{
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.42px;
  color: #000;
  margin-bottom: 10px;
  &:lang(ar) {
    letter-spacing: 1px;
  }
}


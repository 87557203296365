.recipes-page-wrapper {
  background-color: #fff;
  .recipes-hero {
    height: 380px;
    // background-image: url('../../assets/img/Recipes_bk.png');
    background-image: url('../../assets/img/Recipie-en.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    &:lang(ar) {
      background-image: url('../../assets/img/Recipie-ar.jpeg');
    }
    h1 {
      color: #ffffff;
      text-transform: uppercase;
      font-size: 44px;
      font-weight: 600;
      line-height: 1.55;
    }
    @media only screen and (max-width: 992px) {
      height: 180px;
      h1 {
        font-size: 34px;
      }
    }
  }
  .ant-breadcrumb {
    margin-top: 45px;
    margin-bottom: 30px;
  }
  .recipes-content {
    width: 90%;
    margin: auto;
    @media only screen and (max-width: 992px) {
      width: 100%;
      .recipe-wrapper{
        flex-direction: column;
        .recipe-aside-holder{
          width: 100%;
          min-width: 100%;
          max-width: 100%;
          margin: 0;
        }
      }
    }
  }

  .recipes-grid-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 258px));
    gap: 40px 30px;
    min-height: 200px;
    margin-bottom: 60px;
    @media only screen and (max-width: 992px) {
      grid-template-columns: 1fr;
    }
  }
}
.recipes-card-holder {
  border-radius: 10px 10px 0 0;
  box-shadow: 0 10px 20px 0 rgba(170, 245, 237, 0.16);
  max-width: 330px;
  height: fit-content;
  .recipes-card-img {
    height: 175px;
    img {
      border-radius: 10px 10px 0 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .recipes-card-content {
    border-radius: 10px;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    box-shadow: 0 10px 20px 0 rgba(199, 199, 199, 0.16);
    background-color: #6bc047;
    margin-top: -10px;
    padding: 12px 28px;
    min-height: 86px;
    display: flex; 
    align-items: center;
    justify-content: center;
    .recipes-card--title {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.69;
      letter-spacing: -0.48px;
      text-align: center;
      color: #fff;
      // margin-bottom: 8px;
    }
    .recipes-card--text {
      font-size: 14px;
      font-weight: normal;
      line-height: 1.6;
      letter-spacing: -0.42px;
      text-align: center;
      color: #fff;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      &:lang(ar) {
        letter-spacing: 1px;
      }
    }
  }
}
.recipe-wrapper {
  display: flex;
  .recipe-aside-holder {
    width: 340px;
    min-width: 340px;
    max-width: 340px;
    margin-left: 20px;
    .recipes-card-holder {
      margin-bottom: 20px;
    }
    &:lang(ar) {
      margin-left: 0;
      margin-right: 20px;
    }
  }
}

.recipe-content-holder {
  flex: 1;
  .recipe--img {
    height: 350px;
    margin-bottom: 45px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }
  .recipe-section {
    margin-bottom: 30px;
    .recipe-section--title {
      font-size: 20px;
      font-weight: bold;
      line-height: 1.35;
      letter-spacing: -0.6px;
      color: #000;
      margin-bottom: 15px;
    }
    .recipe-section--text {
      font-size: 14px;
      line-height: 1.6;
      letter-spacing: -0.42px;
      color: #000;
      width: 90%;
      margin-bottom: 5px;
      &:lang(ar) {
        letter-spacing: 1px;
      }
    }
  }
  .recipe-step-holder {
    margin-bottom: 30px;
    .recipe-step--title {
      font-size: 18px;
      letter-spacing: -0.54px;
      color: #000;
      margin-bottom: 5px;
      &:lang(ar) {
        letter-spacing: 1px;
      }
    }
    .recipe-step--text {
      font-size: 14px;
      letter-spacing: -0.42px;
      color: #000;
      line-height: 1.93;
      &:lang(ar) {
        letter-spacing: 1px;
      }
    }
  }
}

.checkout-page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f9fff6;
}

.cart-checkout-wrapper {
  display: flex;
  flex: 1;
  padding-top: 40px;
  padding-bottom: 60px;
  max-width: 1366px;
  margin: auto;
}
.checkout-main {
  padding: 40px 28.5px 26px;
  padding-bottom: 0;
  background-color: #ffffff;
  max-width: 800px;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 40px;
  border-radius: 10px;
  border: solid 1px #dbdbdb;
  .checkout-items-list{
    border-bottom : solid 1px #b8b8b8;
  }
  .checkout-items-title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 30px;
    text-transform: uppercase;
  }
  .checkout-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 45px;
    p {
      font-size: 14px;
      font-weight: 500;
      color: #ababab;
      text-transform: uppercase;
    }
  }

  .item-holder {
    display: flex;
    // border-bottom: solid 1px #e5e5e5;
    margin-bottom: 20px;
    // padding-bottom: 20px;
    .product-img {
      width: 60px;
      height: 60px;
      margin-right: 60px;
      &:lang(ar) {
        margin-right: 0;
        margin-left: 60px;
      }
      .ant-image {
        width: 100%;
        height: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .product-details-wrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .product-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .product-name {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.6;
      color: #000000;
    }
    .product-count {
      font-size: 14px;
      font-weight: 600;
    }
    .price-holder {
      text-align: center;
      display: flex;
      align-items: center;
      p {
        font-size: 16px;
        font-weight: 600; 
        color: #6cc04a; 
        margin: 0 4px;
      }
      .price-after {
        // span {
        //   font-size: 10px;
        //   font-weight: normal;
        //   color: #ababab;
        // }
      }
      .price-before {
        font-size: 14px;
        font-weight: 600; 
        color: #6cc04a;
        text-decoration: line-through;
      }
    }
  }
  .cart-aside-card--title {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    margin-bottom: 8px;
  }
  .aside-card-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-bottom: 10px;

    .card-row-title {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.6; 
      color: #000000;
      margin-right: 14px;
      &:lang(ar) {
        margin-right: 0;
        margin-left: 14px;
      }
    }
    h5.card-row-title {
      font-size: 13px;
    }
    .card-row-btn {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.21;
      color: #6cc04a; 
      cursor: pointer;
      text-decoration: underline;
      min-width: 85px;
      text-align: right;
      &:lang(ar) {
        text-align: left;
      }
      // text-transform: uppercase;
    }
  }
  .cart-aside-promo {
    border-bottom: solid 1px #b8b8b8;
    margin-bottom: 28px;
    padding-bottom: 10px;
    .cart-aside-promo--header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 14px;
      .promo--title {
        font-size: 14px;
        font-weight: 600;
        color: #212529;
      }
      .promo--status {
        font-size: 14px;
        font-weight: 600;
        color: #00b181;
      }
    }
    .promo-apply {
      display: flex;
      input {
        border-radius: 5px;
        border: solid 1px #e0e0e0;
        background-color: #ffffff;
        margin-right: 13px;
        width: fit-content;
        min-width: 221.4px;
        font-size: 14px;
        font-weight: 600; 
        color: #6cc04a;
        &:lang(ar) {
          margin-right: 0;
          margin-left: 13px;
        }
      }
    }
  }
  .order-deliver-holder {
    font-size: 16px;
    font-weight: 500;
    color: #07c4b7;
    margin-bottom: 40px;
    margin-top: 30px;
    img {
      margin-right: 16px;
      &:lang(ar) {
        margin-right: 0;
        margin-left: 16px;
      }
    }
  }
  .checkout-total-holder {
    // border-top: solid 1px #e5e5e5;
    padding-top: 44px;
    padding-bottom: 16px;
    .total-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 19px;
      &:last-of-type {
        margin-bottom: 0;
      }
      h4 {
        font-size: 18px;
        font-weight: normal;
        span {
          font-size: 18px;
          font-weight: normal; 
          color: #07c4b7;
          margin: 0 3px;
        }
      }
      h5 {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: -0.54px;
        color: #6cc04a;
        &:lang(ar) {
          letter-spacing: 1px;
        }
        // span {
        //   font-size: 14px;
        //   font-weight: normal;
        //   margin: 0 3px;
        // }
      }
    }
  }
  .checkout-final-total {
    display: flex;
    align-items: center;
    height: 70px;
    border-bottom: solid 1px #b8b8b8; 
    margin-bottom: 26px;
    .total-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      h4 {
        font-size: 20px;
        font-weight: 500; 
        color: #000;
        letter-spacing: 0.19px;
        span{
          font-size: 16px;
          font-weight: 500; 
          color: #878787;
          margin: 0 4px;
          &:lang(ar) {
            letter-spacing: 1px;
          }
        }
      }
      h5 {
        font-size: 20px;
        font-weight: 500;
        color: #6cc04a;
        letter-spacing: -0.6px;
      }
    }
  }
  .promo-desc-holder{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom : 16px;
    height: 100%;
    p{
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.15px;
      color: #6bc047;
      &.apply-confirm{
        font-size: 14px; 
        font-weight: 600; 
        color: #6cc04a;
      }
    }
  }
  .row-info{
    display: flex;
    align-items: center;
  }
  .saved-holder {
    padding: 8px 109px 8px 112px;
    border-radius: 4px;
    // -webkit-filter: blur(10px);
    // filter: blur(10px);
    border: solid 1px #c3e6cb;
    background-color: #d4edda;
    width: 80%;
    height: 33px;
    margin: 0 auto;
    margin-top: -25px;
    margin-bottom: 35px;
    p {
      display: flex;
      align-items: center; 
      justify-content: center;
      font-size: 14px;
      font-weight: 300;
      color: #00b181;
      span {
        font-weight: bold;
        display: inline;
        margin: 0 4px;
      }
      img {
        margin-right: 13px;
        &:lang(ar) {
          margin-right: 0;
          margin-left: 13px;
        }
      }
    }
  }
}

.checkout-main-footer{ 
  max-width: 800px;
  margin: auto;
  margin-bottom: 100px;
  button{
    font-size: 14px;
  font-weight: 600;
  width: 150px;
  }
  button:first-of-type{
    margin-right: 10px; 
    background-color: #6bc047;
    border: 0;
    &:lang(ar) {
      margin-right: 0;
      margin-left: 10px;
    }
  }
}

.promocode-text-holder{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%; 
  .applied-txt{
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.15px;
    color: #6bc047;
    // margin-bottom: 16px;
  }
}

.promocode-input-holder{
  margin-bottom: 10px;
  .input-holder{
    margin-bottom: 0;
  }
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 2s linear infinite;
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
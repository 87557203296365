.profile-page-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f9fff6;
}
.profile-content {
  margin-top: 40px;
  margin-bottom: 100px;
  // position: relative;
  .cancel-order-btn{
    position : absolute;
    bottom: 0; 
    left: 50%; 
    transform: translateX(-50%);
  }
  .ant-tabs {
    margin-top: 24px; 
    @media only screen and (max-width: 992px) {
      flex-direction: column; 
      .ant-tabs-nav-wrap{
        width: 100% !important;
        margin-bottom: 0;
        padding-top: 0 !important;
        &:lang(ar){
          direction: rtl;
        }
      }
      .ant-tabs-content-holder{
        margin: 0 !important;
        padding: 20px 10px;
        .user-order-track .order-status-steper{
          width: 100%;
          .status-card-name{
            font-size: 13px;
          }
        }
        .subscribe-content-holder{
          flex-direction: column;
          align-items: flex-start;
          .ant-checkbox-wrapper{
            margin-bottom: 10px;
          }
          > div{
            display: flex;
          }
        }
        .address-action-btn .dlt-btn{
          margin: 0;
        }
      }
  }
    &:lang(ar){ 
      direction: ltr; 
    }
    .ant-tabs-nav-wrap {
      width: 280px;
      padding-top: 20px;
      border-radius: 5px;
      box-shadow: none;
      background-color: #ffffff;
      // margin-right: 16px;
      // &:lang(ar){
      //     margin-right: 0;
      //     margin-left: 16px;
      // }
      .ant-tabs-ink-bar {
        width: 8px;
        border-radius: 6px 0 0 6px;
      }
      .ant-tabs-tab {
        padding: 14px 24px;
        &:lang(ar){ 
          direction: rtl;  
        }
        @media only screen and (max-width: 992px) {
          margin: 0;
          padding: 16px;
        }
        &.ant-tabs-tab-active{
          .ant-tabs-tab-btn {
            font-weight: 600; 
            color: #000;
            text-transform: uppercase;
          }
        }
      }
      .ant-tabs-tab-btn {
        font-size: 16px;
        letter-spacing: -0.48px;
        color: #6bc047;
        text-transform: capitalize;

      }
    }
  }
  .ant-tabs-content {
    flex: 1;
    height: 100%;
  }
  .ant-tabs-content-holder {
    margin: 0 16px !important; 
    padding: 20px;
    border-radius: 5px;
    box-shadow: none;
    border: 0 !important;
    background-color: #ffffff;
    overflow : auto; 
    position: relative; 
    &:lang(ar){
      direction: rtl; 
    }
    .ant-tabs-tabpane {
      padding: 0 !important;
      flex: 1;
      .ant-spin-nested-loading{
        height: 100%;
        .ant-spin-container{
          height: 100%;
        }
      }
    }
    .btn-action-holder{
      button[type=submit]{ 
        min-width: 133px;
        height: 59px;
        // height: 48px;
        // font-size: 18px;
        // padding: 5px 24px;
      }
    }
  }
  .ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav{
    height: fit-content;
  }
  .ant-tabs-nav-list{
    > .ant-tabs-tab{
      font-size: 15px;
  font-weight: normal;
  color: #c8c8c8;
  .anticon{
    margin-right: 40px;
    @media only screen and (max-width: 992px) {
      margin-right: 16px; 
    }
    svg{
      font-size: 20px;
    }
    &:lang(ar){
      margin-right: 0; 
      margin-left: 40px;
      @media only screen and (max-width: 992px) {
        margin-left: 16px;
      }
    }
  }
      &:nth-child(5){
        background-color: #ffdede;
        color: #fe5252 !important;  
        display: flex;
        justify-content: center;
        text-transform: uppercase;
        >div{
          color: #fe5252 !important;  
        }
        .anticon{
          margin-right: 14px;
          &:lang(ar){
            margin-right: 0;  
            margin-left: 14px;
          }
        }
      }
    }
  }
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 !important;
}

.personal-data-wrapper {
  .form-edit-text{
    font-size: 14px; 
    font-weight: 500;
    letter-spacing: -0.42px;
    color: #000;
    margin-bottom: 20px;
    &:lang(ar) {
      letter-spacing: 1px;
    }
  }
  .btn-action-holder {
    // text-align: right;
  }
}

.addresses-tab-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.no-address-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  img {
    width: 50px;
  }
  p {
    font-size: 14px;
    font-weight: normal;
    line-height: 2.29;
    color: #000000;
    margin: 20px 0;
  }
}

.form-holder-2-col {
  .ant-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 13px;
    @media only screen and (max-width: 992px) {
      display: flex;
      flex-direction: column; 
    }
  }
  .form-hinte-holder {
    font-size: 14px;
    font-weight: normal;
    color: #000000;
    // margin: 10px 0;
    margin-top: 20px;
    margin-bottom: 6px;
    span {
      font-size: 14px;
      font-weight: 500;
      color: #6cc04a;
      cursor: pointer;
      text-transform: uppercase; 
      text-decoration: underline;
      letter-spacing: -0.42px;
      &:lang(ar) {
        letter-spacing: 1px;
      }
    }
  }
  .btn-action-holder{
    // text-align: right;
    // &:lang(ar){
    //   text-align: left; 
    // }
  }
}

.form-holder-1-col{
  .btn-action-holder{
    text-align: right;
    &:lang(ar){
      text-align: left; 
    }
  }
}



@mixin input-placeholder {
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}
.main-header {
  background-color: #6cc04a;
  margin-bottom: 20px;
}
.main-header-content {
  display: flex;
  flex-direction: column;
  // align-items: center;
  // justify-content: space-between;
  // padding-bottom: 20px;
  .lang-search {
    display: flex;
    align-items: center;
    flex: 1;
    .search-input-holder {
      flex: 1;
      max-width: 400px;
      .ant-select-auto-complete {
        width: 100%;
        .ant-input-affix-wrapper {
          border-radius: 10px;
          background-color: #fff;
          height: 49px;
          border: 0 !important;
          padding: 6px 24px;
        }
        .ant-input-affix-wrapper > input.ant-input {
          background-color: #fff;
          @include input-placeholder() {
            font-size: 12px;
            color: #6bc047;
            letter-spacing: 0.11px;
          }
        }
        .anticon-search {
          color: #6bc047;
          font-size: 21px;
        }
      }
    }
    .menu-list-wrapper {
      margin-left: 20px;
      // &:lang(ar){
      //     margin-left: 0;
      //     margin-right: 20px;
      // }
      ul {
        display: flex;
        align-items: center;
        li {
          font-size: 14px;
          font-weight: 500;
          color: #fff;
          margin-right: 20px;
          cursor: pointer;
          a {
            font-size: 14px;
            font-weight: 500;
            color: #fff;
          }
        }
      }
      .ant-dropdown-trigger {
        // color: #6bc047;
        // text-transform: uppercase;
      }
    }
  }
  .user-adderss-holder {
    display: flex;
    align-items: center;
    margin-right: 30px;
    margin-left: 30px;
    cursor: pointer;
    &:lang(ar) {
      margin-right: 30px;
      margin-left: 16px;
    }
    p {
      font-size: 14px;
      // font-weight: 500;
      color: #fff;
      max-width: 125px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 1.4;
      text-decoration: underline;
      &:lang(ar) {
        text-decoration: none;
      }
    }
    img {
      margin-right: 10px;
      &:lang(ar) {
        margin-right: 0;
        margin-left: 10px;
      }
    }
    @media only screen and (max-width: 992px) {
      display: none;
    }
  }
  .lang-holder {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
    text-decoration: underline;
    margin-left: 30px;
    margin-right: 30px;
    &:lang(ar) {
      margin-right: 30px;
      margin-left: 30px;
    }
    @media only screen and (max-width: 992px) {
      margin-right: 20px !important;
      margin-left: 20px !important;
    }
  }
  .cart-login {
    display: flex;
    align-items: center;
    .cart-holder {
      margin-right: 30px;

      &:lang(ar) {
        margin-right: 0;
        margin-left: 30px;
        .ant-scroll-number{
            right: -15px;
            left : auto;
        }
      }
      a {
        font-size: 16px;
        font-weight: normal;
        color: #000000;
        display: flex;
        align-items: center;
      }
      svg {
        font-size: 20px;
        margin-left: 6px;
        color: #fff;
        &:lang(ar) {
          margin-left: 0;
          margin-right: 6px;
        }
      }
    }
    .login-dropdown {
      font-size: 16px;
      font-weight: normal;
      color: #fff;
      background-color: transparent;
    }
    .user-drop-points{
      display: flex;
      flex-direction: column;
      align-items: center;
      .points-tag{
        width: fit-content;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-radius: 9.5px;
        color: #0a7740;
        border: solid 3px #6cc04a;
        background-color: #FFF;
        font-size: 13px;
        padding: 4px 10px;
        display: none;
        span{
          font-weight: bold;
          margin: 0 2px;
        }
      }
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
  }
  .main-header-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .main-header-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    display: none;

    > ul {
      display: flex;
      align-items: center;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 6px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #6cc04a;
        // width: 6px;
        border-radius: 4px;
      }
      > li {
        margin-right: 30px;
        &:lang(ar) {
          margin-right: 0;
          margin-left: 30px;
        }
        a {
          font-size: 16px;
          color: #000000;
        }
        &:last-of-type {
          margin-right: 0;
        }
        &.offer-link {
          width: 101px;
          height: 32px;
          margin: 0 0 0 68px;
          padding: 7px 20px;
          border-radius: 16px;
          background-color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          a {
            font-size: 15px;
            font-weight: bold;
            color: #6cc04a;
          }
          &:lang(ar) {
            margin: 0 68px 0 0;
          }
        }
      }
    }
  }
  .login-link {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    margin-left: 12px;
    &:lang(ar) {
      margin-left: 0;
      margin-right: 12px;
    }
    .anticon {
      margin: 0 10px;
      font-weight: 500;
      font-size: 16px;
    }
  }
}
.bars-menu-icon {
  margin-left: 6px;
  svg {
    color: #fff;
  }
}
.bars-menu-icon,
.close-icon-menu {
  display: none !important;
  svg {
    font-size: 26px;
  }
}
.khier-logo{
  margin: 10px 20px;
  img{
    width: 80px;
  }
}
.logo-res-menu {
  display: none;
}
@media only screen and (max-width: 992px) {
  .login-dropdown {
    display: none !important;
  }
  .main-header-bottom {
    display: flex;
  }
  .bars-menu-icon,
  .close-icon-menu {
    display: block !important;
  }
  .logo-res-menu {
    display: block;
    text-align: center;
  }
  .close-icon-menu {
    padding: 16px 20px;
    background-color: white;
    position: absolute;
    top: 0;
    right: 10px;
    z-index: 5;
    svg {
      color: #fe5252;
    }
    &:lang(ar) {
      right: auto;
      left: 10px;
    }
  }
  .search-input-holder {
    display: none;
  }
  .cart-holder {
    display: none;
    a {
      font-size: 0 !important;
    }
  }
  .login-dropdown {
    padding: 0 !important;
  }
  .main-header-bottom {
    // display: none !important;
    display: block !important;
    position: fixed;
    width: 88%;
    background-color: #fff;
    top: 0;
    left: 0;
    bottom: 0;
    margin: 0 !important;
    padding: 0;
    flex-direction: column;
    align-items: flex-end !important;
    transition: all 0.3s ease-in-out;
    transform: translate(-100%);
    &:lang(ar) {
      transform: translate(100%);
      left: auto;
      right: 0;
    }
    &.active {
      transform: translate(0) !important;
    }
    ul {
      flex-direction: column;
      height: 100%;
      width: 100%;
      padding: 40px 20px;
      padding-top: 55px;
      align-items: flex-start !important;
      > li {
        width: 100%;
        margin: 0 !important;
        margin-bottom: 30px !important;
        a {
          font-size: 22px !important;
        }
        &.offer-link {
          width: 130px !important;
          height: 40px !important;
          margin: 16px auto !important;
        }
      }
    }
  }
  .main-header {
    position: sticky;
    top: 0;
    z-index: 333;
    padding-top: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    .main-header-content {
      padding-bottom: 10px;
    }
  }
  .main-header-content .lang-search .menu-list-wrapper ul {
    display: none;
  }
  .bars-menu-icon {
    order: -1;
  }
  .khier-logo {
    display: none !important;
    img {
      width: 60px;
    }
  }
  .lang-search {
    // display: none !important;
    .search-input-holder {
      display: flex;
    }
  }
  .main-header-content .main-header-top {
    padding: 3px;
  }
  .main-header-content
    .lang-search
    .search-input-holder
    .ant-select-auto-complete
    .ant-input-affix-wrapper {
    height: 44px;
  }
}
.user-dropdown-wrapper {
  .ant-dropdown-menu-item-icon {
    font-size: 17px;
  }
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    font-size: 15px;
    font-weight: 500;
    color: #000000;
    padding: 7px 12px;
  }
  .ant-dropdown-menu {
    padding-top: 10px;
    padding-bottom: 10px;
    min-width: 160px;
    // padding-right: 16px;
    // &:lang(ar) { 
    //   padding-right: 0;
    //   padding-left: 16px;
    // }
  }
}
.search-show-wrapper {
  .ant-input-search {
    .ant-input-affix-wrapper {
      height: 60px;
      padding: 16.8px 30.4px 10px 30.4px;
      border-radius: 30px !important;
      border: solid 1px #c8c8c8;
      background-color: #ffffff;
      input {
        font-size: 16px;
        font-weight: bold;
        color: #000000;
      }
    }
    .anticon-close-circle {
      visibility: visible;
      svg {
        color: #ff0000;
        font-size: 26px;
      }
    }
  }
  .ant-input-group-addon {
    display: none;
  }
  & + .main-header-content {
    .main-header-top {
      display: none;
    }
  }
}

.support-text {
  margin-right: 33px;
  color: #00b181;
  a {
    font-size: 16px;
    color: #00b181;
    font-weight: 600;
  }
  &:lang(ar) {
    margin-right: 0;
    margin-left: 33px;
  }
  .anticon {
    margin: 0 10px;
  }
  @media only screen and (max-width: 992px) {
    margin: 0 !important;
    a {
      font-size: 0;
    }
  }
}

.category-menu-dropdown {
  width: 100vw;
  height: 0;
  top: 105px !important;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  // overflow: auto;
  overflow: auto;
  position: absolute;
  opacity: 0;
  z-index: -5;
  visibility : hidden;
  transition: all ease-in-out 0.3s;
  &.active{
    opacity: 1;
    z-index: 555;
    visibility : visible;
    transition: all ease-in-out 0.3s;
    height: calc(100vh - 100px);
  }
  &::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #6cc04a;
    // width: 6px;
    border-radius: 4px;
  }
  .ant-menu {
    padding: 30px 40px;
    box-shadow: none !important;
    height: 100%;
    // overflow: auto;
    /* width */
    ul {
      box-shadow: none !important;
    }
    .ant-menu-submenu-vertical {
      width: 230px;
      &.ant-menu-submenu-open {
        .ant-menu-submenu-title {
          color: #000;
        }
        background-color: #e9ffde;
      }
    }
    .ant-spin {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ffffffb3;
      z-index: 4;
    }
  }

  .ant-menu-item,
  .ant-menu-submenu-title {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.42px;
    color: #6cc04a;
    height: 40px;
    &:lang(ar) {
      letter-spacing: 0.5px;
    }
    &:hover {
      background-color: #e9ffde;
      color: #000;
    }
  }
  .anticon svg {
    fill: #6bc047;
  }
}

.ant-menu.category-menu-lvl-1,
.ant-menu.category-menu-lvl-2 {
  box-shadow: none !important;
  padding-top: 0;
  top: 135px !important;
  width: 230px;
  .ant-menu-submenu-vertical {
  }
  ul {
    padding-top: 0;
    box-shadow: none !important;
  }
  .ant-menu-item,
  .ant-menu-submenu-title {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.42px;
    color: #6cc04a;
    height: 40px;
    &:lang(ar) {
      letter-spacing: 0.5px;
    }
    &:hover {
      background-color: #e9ffde;
      color: #000;
    }
  }
  .anticon svg {
    fill: #6bc047;
  }
  .ant-menu-submenu {
    &.ant-menu-submenu-open {
      background-color: #e9ffde;
      .ant-menu-submenu-title {
        color: #000;
      }
    }
  }
}

.drawer-main-header-bottom {
  .ant-drawer-body {
    padding: 0;
    &::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #6cc04a;
      // width: 6px;
      border-radius: 4px;
    }
  }
  .cat-res-menu-holder {
    > li {
      margin-bottom: 0 !important;
    }
    .ant-menu-sub.ant-menu-inline {
      > li {
        margin-bottom: 0 !important;
      }
    }
    .ant-menu-item,
    .ant-menu-submenu-title {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.42px;
      color: #000;
      &:lang(ar) {
        letter-spacing: 0.5px;
      }
      &:hover {
        background-color: #e9ffde;
        color: #6cc04a;
      }
    }
  }
}

.menu-no-icon {
  .ant-dropdown-menu-submenu-expand-icon,
  .ant-menu-submenu-arrow {
    display: none;
  }
}

.ant-menu-submenu-popup.cat-menu-hidden {
  display: none;
}

.cat-menu-overlay-holder{
  &:lang(ar) {
    .ant-menu-item .ant-menu-submenu-expand-icon, .ant-menu-submenu-title .ant-menu-submenu-expand-icon{
      left: 8px;
      right: auto;  
    }
  }
}

.ant-menu.category-menu-lvl-1, .ant-menu.category-menu-lvl-2{
  &:lang(ar) {
     .ant-menu-submenu-arrow{
      left: 8px;
      right: auto;  
      transform: rotate(180deg);
    }
  }
}

.ant-menu.category-menu-lvl-1 .ant-menu-item, .ant-menu.category-menu-lvl-1 .ant-menu-submenu-title, .ant-menu.category-menu-lvl-2 .ant-menu-item, .ant-menu.category-menu-lvl-2 .ant-menu-submenu-title{
  &:lang(ar) {
    text-align: right;
  }
}

.category-li{
  display: flex;
  align-items: center;
  position: relative;
  .ant-spin{
    position: absolute;
    left: -22px;
    width: fit-content;
    svg{
      color: #fff; 
    }
    &:lang(ar) { 
      right: -22px;
      left: auto;
    }
  }
}

@media only screen and (max-width: 992px) {
  .login-link, .user-icon-holder {
    display: none;
  }
}

.log-out-holder{
  display: flex;
  align-items: center;
  color: #fe5252;
  a{
    color: #fe5252 !important;
  }
  .anticon{
    font-size: 21px; 
    margin: 0 16px;
    &:lang(ar) {
      transform: scale(-1);
    }
  }
}
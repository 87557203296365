@import "../../scss/mixins";
.product-page-wrapper{
    display: flex;
    flex-direction: column;
    background-color: #fff;
    .product-content{
        .ant-breadcrumb{
            margin-top: 30px;
            margin-bottom: 40px;
        }
        .discount-section-wrapper{
            border-top: solid 1px #b8b8b8;
            .section-title{
                font-size: 20px;
                text-transform: capitalize;
                margin-bottom: 60px;
            }
        }
    }
    .product-info-content{
        display: flex;
        // margin-bottom: 100px;
        @include mq("ipad"){
            flex-direction: column;
        }
    }
    .product-info-wrapper{
        .product-img-holder{
            width: 25%;
            max-width: 360px; 
            position: relative; 
            margin-right: 30px;
            border: solid 1px #d6d6d6;
            border-radius: 10px;
            height: 281px;
            // box-shadow: 0 10px 20px 0 #ebf2f9;
            &:lang(ar){
                margin-right: 0; 
                margin-left: 30px;
            }
            .ant-image{
                border-radius: 10px;
                width: 100%;
                height: 100%;
                background-color: #FFF;
                // padding: 10px; 
            }
            img{
                width: 100%;
                height: 100%;
                border-radius: 10px;
                object-fit: contain; 
                // object-fit: cover; 
                mix-blend-mode: multiply;
                @include mq("ipad"){
                    object-fit: contain; 
                }
            }
            @include mq("ipad"){
                width: 100%;
                max-width: auto;  
                height: 40vh;
                margin: 0 !important;
                margin-bottom: 20px !important;
            }
        }
        .product-details-holder{
            flex : 1;
            .product-type{
                padding: 0;
            }
            .product-name-holder{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 34px;
                h4{
                    font-size: 20px;
                    line-height: 1.6;
                    color: #000000;
                    width: 65%;
                    @include mq("tablet"){
                        width: 95%;
                        text-align: center;
                        margin-bottom: 20px;
                    }
                }
                .price-holder{
                    display: flex;
                    align-items: center;
                    .price-after{
                        font-size: 20px;
                        font-weight: 600;
                        color: #6cc04a;
                        margin-right: 70px;
                        display: flex;
                        align-items: center;
                        &:lang(ar){ 
                            margin-right: 0;
                            margin-left: 70px;
                        }
                        @include mq("tablet"){
                            margin-right: 20px;
                            &:lang(ar){ 
                                margin-right: 0;
                                margin-left: 20px;
                            }
                        }
                    }
                    .price-before{
                        font-size: 16px;
                        color: #f00;
                        font-weight: 600;
                        text-decoration: line-through;
                        margin-right: 20px;
                        white-space: nowrap;
                        &:lang(ar){ 
                            margin-right: 0;
                            margin-left: 20px;
                        }
                    }
                }
                @include mq("tablet"){
                    flex-direction: column; 
                }
            }
            .product-desc{
                margin-top: 35px;
                margin-bottom: 50px;
                h5{
                    font-size: 16px;
                    font-weight: bold;
                    color: #000000;
                    line-height: 1.19; 
                }
                p{
                    font-size: 15px;
                    line-height: 1.6;
                    color: #000; 
                    margin-top : 16px;
                    width: 75%;
                    @include mq("tablet"){
                        width: 100%;
                    }
                }
            }
            .card-count-btns .btns-wrapper{
                flex-direction: row;
            }
        }
    }
    .product-details-holder{
        // padding: 0 40px;
        // box-shadow: 0 10px 20px 0 #ebf2f9;
        background-color: #ffffff;
        @include mq("phablet"){
            padding: 20px;
        }
    }
    .product-cart-wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .card-count-btns{
            width: fit-content;
            .card-control{
                margin-left: 20px;
                &:lang(ar) { 
                    margin-left: 0;
                    margin-right: 20px;
                }
            }
        }
        @include mq("phablet"){
            flex-direction: column;
            .card-control{ 
                margin-bottom: 0; 
            }
        }
    }
    .card-count-holder{
        display: flex;
        align-items: center;
        // flex-direction: row;
        position: relative;
        .product-count{
          width: 70px;
          text-align: center;
          font-size: 22px;
          user-select: none; 
        }
        svg{
            font-size: 22px;
            fill: #fff;
            cursor: pointer;
        }
        button{
            border: 0; 
            padding: 0;
            border-radius: 10px;
            width: 33px;
            height: 33px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #4D9D2C;
          .ant-btn-loading-icon{
            position: absolute;
            right: 0;  
            width: 30px;
            background: #FFF;
          }
          &:disabled{
            // svg{
            //   fill: gray;
            // }
          }
        }
      }
      .add-fav-dlt-holder{
          .add-btn{ 
              min-width: 220px;
            }
            @include mq("phablet"){
                width: 100%; 
                text-align: center;
                    .add-btn{ 
                    min-width: auto;
                    width: 90%;
                }
            }
      }
      .add-fav-dlt-holder{
        .dlt-btn{
          margin-right: 40px;
          &:lang(ar){
              margin-right: 0;
              margin-left: 40px;
            }
            svg{
              fill: #ff0000; 
            }
        }
           svg{
            // fill: #ff0000;
            font-size: 28px;
            cursor: pointer; 
          }
      }
      .product-discount {
        position: absolute;
        top: 0;
        left: 0;
        width: 68px;
        height: 68px;
        padding: 24px 14px 27px 15px;
        font-size: 16px; 
        font-weight: 600;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom-right-radius: 10px;
        border-top-left-radius: 10px; 
        z-index: 2;
        box-shadow: 0 20px 40px 0 rgba(116, 116, 116, 0.16);
        background-color: #da1b15;
        &:lang(ar){
          border-radius: 0;      
          border-bottom-left-radius: 10px; 
          border-top-right-radius: 0;
          right: 0;
        }
      }
}
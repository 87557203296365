



.trending-brand-wrapper {
  margin-bottom: 75px;
  height: 315px;
  display: flex;
  align-items: center; 
  .trending-bg-left {
    position: relative;
    background-color: #6cc04a;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    // width: 30%;
    // max-width:536px;
    // width: 536px;
    width: 35vw;
    .content-title {
      // padding-top: 50px;
      padding-right: 40px;
      width: 270px;
      position: relative;
      z-index: 5;
      h2 {
        font-size: 24px;
        font-weight: 600;
        line-height: 1.54;
        color: #fff;
      }
      a {
        font-size: 14px;
        font-weight: 600;
        color: #f3ba35; 
        line-height: 2.64;
        text-decoration: underline;
        font-weight: bold;
      }
    }
    .trending-hero {
      position: absolute;
      top: 30px;
      right: 0;
      transform: translateX(50%);
      &:lang(ar) {
        left: 0;
        right: auto;
        transform: translateX(-50%);
      }
      img {
        width: 256px;
        height: 255px;
        border-radius: 10px;
        box-shadow: 0 40px 80px 0 rgba(86, 86, 86, 0.26);
      }
    }
    .trending-lines{
        position: absolute;
        top: 60px;
        img{
            width: 130%;
            display: none;
        }
    }
  }
  .trending-brand-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(164px, 164px));
    gap: 24px 30px;
    flex: 1;
    margin-left: 170px; 
    &:lang(ar){ 
      margin-left: 0; 
      margin-right: 170px; 
    }
    @media only screen and (max-width: 1540px) {
      grid-template-columns: repeat(auto-fit, minmax(140px, 140px));
      gap: 16px 20px;
    }

    .brand-holder {
      border-radius: 10px;
      padding: 4px;
      background-color: #fff;
      cursor: pointer;
      img {
        width: 100%;
        height: 104px;
        object-fit: contain;
        mix-blend-mode: multiply;
        border-radius: 10px;
      }
      .brand-title{
        font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  color: #000;
  margin-top: 5px;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .trending-brand-wrapper .trending-bg-left .trending-lines img{
    display: none;
  }
  .trending-brand-content {
    grid-template-columns: 1fr 1fr 1fr !important;
    padding-top: 20px;
  }
  .trending-brand-wrapper .trending-bg-left{
    width: 100%; 
  }
  .home-wrapper .trending-brand-wrapper .trending-bg-left .content-title{
    text-align: center;
    padding-right: 0;
    padding-left: 0;
    padding: 30px 0;
  }
}

@media only screen and (max-width: 330px) {
  .trending-brand-content {
    grid-template-columns: 1fr 1fr !important;
  }
}
.cart-page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fff;
  .ant-breadcrumb {
    margin-top: 20px;
  }
}

.cart-content-wrapper {
  display: flex;
  flex: 1;
  padding-top: 40px;
  padding-bottom: 60px;
  max-width: 1366px;
  margin: auto;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
    .cart-main {
      margin: 0 !important;
      margin-bottom: 20px !important;
    }
  }
  .cart-main {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 50px;
    &:lang(ar) {
      margin-right: 0;
      margin-left: 50px;
    }
  }
  .cart-aside {
    width: 360px;
    @media only screen and (max-width: 992px) {
      width: 100%;
    }
  }
  .cart-aside-content {
    display: flex;
    flex-direction: column;
    // padding: 30px 20px 29px 26px;
    border-radius: 5px;
    // box-shadow: 0 10px 20px 0 #ebf2f9;
    background-color: #ffffff;
    margin-bottom: 16px;
    .cart-aside-title {
      font-size: 14px;
      font-weight: normal;
      color: #000000;
      margin-bottom: 40px;
    }
    .cart-aside-card {
    }
    .cart-aside-card--title {
      font-size: 14px;
      font-weight: 300;
      line-height: 1.21;
      color: #9a9a9a;
      margin-bottom: 8px;
    }
    .aside-card-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
    //   padding-bottom: 20px;
    //   border-bottom: solid 1px #e5e5e5;
      .card-row-title {
        font-size: 14px;
        // font-weight: 600;
        line-height: 1.6;
        color: #000000;
        letter-spacing: -0.42px;
        max-width: 120px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        &:lang(ar) {
          letter-spacing: 1px;
        }
        // margin-right: 14px;
        // &:lang(ar) {
        //   margin-right: 0;
        //   margin-left: 14px;
        // }
      }
      .card-row-btn {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.21;
        letter-spacing: -0.42px;
        color: #6cc04a;
        cursor: pointer;
        text-decoration: underline;
        &:lang(ar) {
          letter-spacing: 1px;
        }
      }
    }
    .cart-aside-promo {
      .cart-aside-promo--header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 14px;
        .promo--title {
          font-size: 14px;
          font-weight: 600;
          color: #212529;
        }
        .promo--status {
          font-size: 14px;
          font-weight: 600;
          color: #00b181;
        }
      }
      .promo-apply {
        display: flex;
        input {
          border-radius: 5px;
          border: solid 1px #e0e0e0;
          background-color: #ffffff;
          margin-right: 13px;
          font-size: 14px;
          font-weight: 600;
          color: #6cc04a;
          &:lang(ar) {
            margin-right: 0;
            margin-left: 13px;
          }
        }
      }
    }
    .total-price-holder {
      height: 77px;
      padding: 27px 30px;
      background-color: #e9ffde;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 10px 10px 0 0;
      .total-title {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.48px;
        color: #000;
      }
      .total-val {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.42px;
        color: #6bc047;
        &:lang(ar) {
          letter-spacing: 1px;
        }
      }
    }
    .aside-btns-holder{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
  }
}

.cart-aside-details{
    border: solid 1px #dbdbdb;
    border-top: 0;
    padding: 30px;
    border-radius: 0 0 10px 10px;
    button{
        flex : 1;
        &:first-of-type{
            margin-right: 12px;
            &:lang(ar) {
              margin-right: 0;
              margin-left: 12px;
            }
        }
        &:disabled{
          background-color: #e9ffde !important;
          border: 0 !important;
          color: #9cef72 !important;
        }
      @media only screen and (max-width: 992px) {
        font-size: 14px !important; 
      }
    }
    .card-row-min-title{
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.48px;
    }
}
.cart-heade-bread{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .ant-breadcrumb{
    margin-top: 0; 
  }
  .clear-cart-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    border-radius: 10px;
    background-color: #fff6f6;
    border-radius: 10px;
    cursor: pointer;
    padding: 12px 24px;
    padding-left: 10px;
    color: #f82727;
    letter-spacing: -0.42px;
    &:lang(ar) {
      letter-spacing: 1px;
    }
    img{
    width: 32px;
    margin-right: 6px;
    }
  }
}

.total-order-warning{
  height: 50px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 16px 20px;
  border-radius: 10px;
  background-color: #ffeab8;
  font-size: 16px;
  line-height: 1.56;
  letter-spacing: -0.48px;
  color: #856404;
  p{
    display: flex;
    align-items: center;
  @media only screen and (max-width: 992px) {
    flex-wrap: wrap;
  }
    a{
      font-weight: 500;
      color: #6cc04a;
      margin: 0 6px;
    }
  }
  @media only screen and (max-width: 992px) {
    height: auto;
    line-height: unset;
    font-size: 15px;
    &:lang(ar) {
      line-height: 1.86;
    }
  }
}
